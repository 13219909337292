export interface Item {
  title: string;
  content: string;
  img: string;
  startColor: string;
  endColor: string;
}
const items: Item[] = [
  {
    title: "明焱",
    content: "",
    img: "https://mingyan-video.oss-cn-zhangjiakou.aliyuncs.com/assets/avatar.png",
    startColor: "deeppink",
    endColor: "coral",
  },
  {
    title: "",
    content: "《二进制恋爱》",
    img: "https://mingyan-video.oss-cn-zhangjiakou.aliyuncs.com/assets/ejzla.jpeg",
    startColor: "SlateBlue",
    endColor: "DeepSkyBlue",
  },
  {
    title: "",
    content: "《极速救援》",
    img: "https://mingyan-video.oss-cn-zhangjiakou.aliyuncs.com/assets/jsjy.jpeg",
    startColor: "tomato",
    endColor: "gold",
  },
  {
    title: "",
    content: "《楼下女友请签收》",
    img: "https://mingyan-video.oss-cn-zhangjiakou.aliyuncs.com/assets/lxnyqqs.jpeg",
    startColor: "#E2B0FF",
    endColor: "#9F44D3",
  },
  // {
  //   title: "",
  //   content: "",
  //   img: "",
  //   startColor: "",
  //   endColor: "",
  // },
  // {
  //   title: "",
  //   content: "",
  //   img: "",
  //   startColor: "",
  //   endColor: "",
  // },
  // {
  //   title: "",
  //   content: "",
  //   img: "",
  //   startColor: "",
  //   endColor: "",
  // },
  // {
  //   title: "",
  //   content: "",
  //   img: "",
  //   startColor: "",
  //   endColor: "",
  // },
  // {
  //   title: "",
  //   content: "",
  //   img: "",
  //   startColor: "",
  //   endColor: "",
  // },
];
export { items };
