// import React, { useState, useEffect } from "react";
import "./App.css";
import Spring from "./components/spring/index";
export interface WindowSize {
  width: number;
  height: number;
}

function App() {
  // const [winSize, setWinSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // useEffect(() => {
  //   function handleResize() {
  //     const { innerWidth: width, innerHeight: height } = window;
  //     setWinSize({
  //       width,
  //       height,
  //     });
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="App" style={{ backgroundColor: "#20232f" }}>
      <Spring />
    </div>
  );
}

export default App;
