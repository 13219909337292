import React, { useRef } from "react";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import styles from "./styles.module.css";
import { Item, items } from "../../assets/item";
interface PageProps {
  offset: number;
  item: Item;
  onClick: () => void;
}

const Page = ({ offset, item, onClick }: PageProps) => {
  const [winSize, setWinSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setWinSize({
        width,
        height,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <ParallaxLayer offset={offset} speed={0.2} onClick={onClick}>
        {/* <div > */}
        <img
          className={styles.slopeBegin}
          style={{
            objectFit: "cover",
          }}
          src={item.img}
          alt=""
        />
        {/* </div> */}
      </ParallaxLayer>

      <ParallaxLayer className={styles.text} offset={offset} speed={0.3}>
        <span style={{ position: "absolute", bottom: "15%", left: 20 }}>
          {item.title}
        </span>
      </ParallaxLayer>

      <ParallaxLayer className={styles.subtext} offset={offset} speed={0.3}>
        <span style={{ position: "absolute", bottom: "6%", left: 20 }}>
          {item.content}
        </span>
      </ParallaxLayer>

      <ParallaxLayer offset={offset} speed={0.6} onClick={onClick}>
        <div
          className={`${styles.slopeEnd}`}
          style={{
            background: `linear-gradient(to right,  ${item.startColor} 0%,${item.endColor} 100%)`,
          }}
        />
      </ParallaxLayer>
    </>
  );
};

export default function App() {
  const parallax = useRef<IParallax>(null);

  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };

  const pageCount = items.length;
  return (
    <Parallax className={styles.container} ref={parallax} pages={pageCount}>
      {items.map((item, index) => (
        <Page
          key={index}
          offset={index}
          item={item}
          onClick={() => scroll(index + 1 === pageCount ? 0 : index + 1)}
        />
      ))}
    </Parallax>
  );
}
